import React from "react";
import { NavLink } from "react-router-dom";
import css from "./menuMobile.module.css";
import { ReactComponent as Comercial } from "../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../assets/icons/tipo/class.svg";

export default function MenuViaturas({
  allListaCarros,
  allListaComercias,
  allListaClassicos,
  allListaMotas,
  infoJson,
  nodeRef,
  onHide,
}) {
  return (
    <div ref={nodeRef} className={`${css.seconddiv} boxMenuMore`}>
      <ul className={`navbar-nav `}>
        {allListaCarros.length > 0 && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                infoJson.Layout.TudoJunto
                  ? infoJson.Layout.Carros.existe
                    ? "automoveis/#listagem_viaturas"
                    : "/viaturas/automoveis"
                  : "/#listagem_viaturas"
              }
            >
              <Berlina style={{ width: "45px", height: "15px" }} /> Automóveis
            </NavLink>
          </li>
        )}
        {allListaClassicos.length > 0 && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                infoJson.Layout.TudoJunto
                  ? infoJson.Layout.Carros.existe
                    ? "classicos/#listagem_viaturas"
                    : "/viaturas/classicos"
                  : "/carros/classicos/"
              }
            >
              <Classico style={{ width: "45px", height: "15px" }} /> Clássicos
            </NavLink>
          </li>
        )}

        {allListaComercias.length > 0 && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                infoJson.Layout.TudoJunto
                  ? infoJson.Layout.Carros.existe
                    ? "comerciais/#listagem_viaturas"
                    : "/viaturas/comerciais"
                  : "/carros/comerciais/"
              }
            >
              <Comercial style={{ width: "45px", height: "15px" }} /> Comerciais
            </NavLink>
          </li>
        )}
        {allListaMotas.length > 0 && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                infoJson.Layout.TudoJunto
                  ? infoJson.Layout.Carros.existe
                    ? "motas/#listagem_viaturas"
                    : "/viaturas/motas"
                  : "/motas/usadas/"
              }
            >
              <Motos style={{ width: "45px", height: "15px" }} /> Motos
            </NavLink>
          </li>
        )}
        
      </ul>
    </div>
  );
}
