import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components/Wrapper";
import Home from "../pages/home/Home";
import { Spinner } from "react-bootstrap";
import PageHolder from "../tools/placeholder/PageHolder";
import PhFicha from "../pages/ficha/placeholder-loading/PhFicha";
import PhFicha2 from "../pages/ficha/placeholder-loading/PhFicha2";
import Layout from "../Tpl/Layout";
import AuthContext from "../hooks/AuthContext";

const Tv = lazy(() => import("../pages/tv/Tv"));

const ModalNewsletter = lazy(() => import("../modal/ModalNewsletter"));
const Privacidade = lazy(() => import("../pages/privacidade/Privacidade"));
const CarrosViaturas = lazy(() => import("../pages/viaturas/CarrosViaturas"));
const CarroFicha = lazy(() => import("../pages/ficha/CarroFicha"));
const Financiamento = lazy(() =>
  import("../pages/financiamento/Financiamento")
);
const Compramos = lazy(() => import("../pages/compramos/Compramos"));
const Consultadoria = lazy(() =>
  import("../pages/consultadoria/Consultadoria")
);
const Seguros = lazy(() => import("../pages/seguros/Seguros"));
const Lavagens = lazy(() => import("../pages/lavagens/Lavagens"));
const Aluguer = lazy(() => import("../pages/aluguer/Aluguer"));
const Oficina = lazy(() => import("../pages/oficina/Oficina"));
const Albuns = lazy(() => import("../pages/album/Albuns"));
const AlbumFicha = lazy(() => import("../pages/album/AlbumFicha"));
const NoticiaFicha = lazy(() => import("../pages/noticias/NoticiaFicha"));
const Noticias = lazy(() => import("../pages/noticias/Noticias"));
const Erro = lazy(() => import("../pages/erro/Erro"));

const Galeria = lazy(() => import("../pages/galeria/Galeria"));
const Testemunhos = lazy(() => import("../pages/testemunhos/Testemunhos"));
const Obrigado = lazy(() => import("../pages/obrigado/Obrigado"));
const Empresa = lazy(() => import("../pages/empresa/Empresa"));
const Contactos = lazy(() => import("../pages/contactos/Contactos"));
const Garantia = lazy(() => import("../pages/garantia/Garantia"));
const TextLegalGarantia = lazy(() =>
  import("../pages/textLegalGarantia/TextLegalGarantia")
);

const renderPaths = (paths, Element) =>
  paths.map((path) => <Route key={path} path={path} element={Element} />);

export default function Rotas({
  setMetaTags,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  allinfoText,
  selectedCarsComparar,
  removeFromCompareAll,
  setTextoModal,
  textoModal,
  setShowModalComparison,
  showModalComparison,
}) {
  const { state } = React.useContext(AuthContext);
  return (
    <>
      <Wrapper>
        <Routes>
          <Route
            element={
              <Layout
                infoJson={infoJson}
                allinfoText={allinfoText}
                selectedCarsComparar={selectedCarsComparar}
                removeFromCompareAll={removeFromCompareAll}
                removeFromCompare={removeFromCompare}
                setTextoModal={setTextoModal}
                textoModal={textoModal}
                setShowModalComparison={setShowModalComparison}
                showModalComparison={showModalComparison}
              />
            }
          >
            {infoJson.Layout.Carros.existe ? (
              <>
                {renderPaths(
                  [
                    "/",
                    "/:viatura",
                    "/:viatura/:marca",
                    "/:viatura/:marca/:modelo",
                  ],

                  <Home
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                )}
              </>
            ) : (
              <Route
                path="/"
                element={
                  <Home
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                }
              />
            )}

            {!infoJson.Layout.Carros.existe && (
              <>
                {renderPaths(
                  [
                    "/viaturas/:viatura",
                    "/viaturas/:viatura/:marca",
                    "/viaturas/:viatura/:marca/:modelo",
                  ],
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <CarrosViaturas
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      selectedItems={selectedItems}
                      addToCompare={addToCompare}
                      removeFromCompare={removeFromCompare}
                    />
                  </Suspense>
                )}
              </>
            )}

            {infoJson.Empresa.existe && (
              <Route
                path={
                  allinfoText.Empresa && allinfoText.Empresa.Url !== ""
                    ? allinfoText.Empresa.Url
                    : infoJson.Empresa.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Empresa
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.Empresa.existe && (
              <Route
                path={
                  allinfoText.Empresa && allinfoText.Empresa.Url !== ""
                    ? allinfoText.Empresa.Url
                    : infoJson.Empresa.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Empresa
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}
            {infoJson.Garantia.existe && (
              <Route
                path={
                  allinfoText.Garantia && allinfoText.Garantia.Url !== ""
                    ? allinfoText.Garantia.Url
                    : infoJson.Garantia.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Garantia
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            {allinfoText.TextLegalGarantia &&
              allinfoText.TextLegalGarantia.Url !== "" && (
                <Route
                  path={
                    allinfoText.TextLegalGarantia &&
                    allinfoText.TextLegalGarantia.Url !== ""
                      ? allinfoText.TextLegalGarantia.Url
                      : infoJson.TextLegalGarantia.url
                  }
                  element={
                    <Suspense fallback={<PageHolder />}>
                      <TextLegalGarantia
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  }
                />
              )}
            {infoJson.Contactos.existe && (
              <Route
                path={
                  allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                    ? allinfoText.Contactos.Url
                    : infoJson.Contactos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Contactos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            <Route
              path={"/privacidade/"}
              element={
                <Suspense fallback={<PageHolder />}>
                  <Privacidade infoJson={infoJson} setMetaTags={setMetaTags} />
                </Suspense>
              }
            />

            {infoJson.Financiamento.existe && (
              <>
                {renderPaths(
                  [
                    `${
                      allinfoText.Financiamento &&
                      allinfoText.Financiamento.Url !== ""
                        ? allinfoText.Financiamento.Url
                        : infoJson.Financiamento.url
                    }`,
                    `${
                      allinfoText.Financiamento &&
                      allinfoText.Financiamento.Url !== ""
                        ? allinfoText.Financiamento.Url
                        : infoJson.Financiamento.url
                    }:codViatura/:marca`,
                  ],
                  <Suspense fallback={<PageHolder />}>
                    <Financiamento
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                )}
              </>
            )}
            {infoJson.Compramos.existe && !infoJson.Compramos.easybuy.existe && (
              <Route
                path={
                  allinfoText.Compramos && allinfoText.Compramos.Url !== ""
                    ? allinfoText.Compramos.Url
                    : infoJson.Compramos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Compramos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            {infoJson.Consultadoria.existe && (
              <Route
                path={
                  allinfoText.Consultadoria &&
                  allinfoText.Consultadoria.Url !== ""
                    ? allinfoText.Consultadoria.Url
                    : infoJson.Consultadoria.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Consultadoria
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Seguros.existe && (
              <Route
                path={
                  allinfoText.Seguros && allinfoText.Seguros.Url !== ""
                    ? allinfoText.Seguros.Url
                    : infoJson.Seguros.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Seguros
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Galeria.existe && (
              <Route
                path={
                  allinfoText.Galeria && allinfoText.Galeria.Url !== ""
                    ? allinfoText.Galeria.Url
                    : infoJson.Galeria.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Galeria
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Lavagens.existe && (
              <Route
                path={
                  allinfoText.Lavagens && allinfoText.Lavagens.Url !== ""
                    ? allinfoText.Lavagens.Url
                    : infoJson.Lavagens.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Lavagens
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Aluguer.existe && (
              <Route
                path={
                  allinfoText.Aluguer && allinfoText.Aluguer.Url !== ""
                    ? allinfoText.Aluguer.Url
                    : infoJson.Aluguer.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Aluguer
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Oficina.existe && (
              <Route
                path={
                  allinfoText.Oficina && allinfoText.Oficina.Url !== ""
                    ? allinfoText.Oficina.Url
                    : infoJson.Oficina.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Oficina
                      setMetaTags={setMetaTags}
                      infoJson={infoJson}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.AlbumClientes.existe && (
              <Route
                path={`${
                  allinfoText.AlbumClientes &&
                  allinfoText.AlbumClientes.Url !== ""
                    ? allinfoText.AlbumClientes.Url
                    : infoJson.AlbumClientes.url
                }:codElemento/*`}
                element={
                  <Suspense fallback={<PageHolder />}>
                    <AlbumFicha
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.AlbumClientes.existe && (
              <Route
                path={
                  allinfoText.AlbumClientes &&
                  allinfoText.AlbumClientes.Url !== ""
                    ? allinfoText.AlbumClientes.Url
                    : infoJson.AlbumClientes.url
                }
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <Albuns
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Testemunhos.existe && (
              <Route
                path={
                  allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                    ? allinfoText.Testemunhos.Url
                    : infoJson.Testemunhos.url
                }
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <Testemunhos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Noticias.existe && !infoJson.Noticias.externas && (
              <Route
                path={`${
                  allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                    ? allinfoText.Noticias.Url
                    : infoJson.Noticias.url
                }:codElemento/*`}
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <NoticiaFicha
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Noticias.existe && (
              <Route
                path={
                  allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                    ? allinfoText.Noticias.Url
                    : infoJson.Noticias.url
                }
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <Noticias
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            <Route
              path="/obrigado"
              element={
                <Suspense
                  fallback={
                    <div className="d-flex align-items-center justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  }
                >
                  <Obrigado setMetaTags={setMetaTags} infoJson={infoJson} />
                </Suspense>
              }
            />

            <Route
              path="carros/:marca/:modelo/:codViatura"
              element={
                <Suspense
                  fallback={
                    <div className="d-flex align-items-center justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  }
                >
                  <CarroFicha
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />

            <Route
              path="motas/:marca/:modelo/:codViatura"
              element={
                <Suspense
                  fallback={
                    <>
                      {infoJson.Layout.Ficha.tipo === 1 ? (
                        <PhFicha infoJson={infoJson} />
                      ) : (
                        <PhFicha2 infoJson={infoJson} />
                      )}
                    </>
                  }
                >
                  <CarroFicha
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />

            {state.infoPrincipal.length !== 0 &&
              state.infoPrincipal[0].EmailNewsletter && (
                <>
                  <Route
                    path="/newsletter"
                    element={
                      <Suspense
                        fallback={
                          <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" />
                          </div>
                        }
                      >
                        <ModalNewsletter
                          infoJson={infoJson}
                          setMetaTags={setMetaTags}
                          infoPrincipal={state.infoPrincipal}
                        />
                      </Suspense>
                    }
                  />
                </>
              )}

            {renderPaths(
              [`*`, `erro`],
              <Suspense
                fallback={
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                }
              >
                <Erro setMetaTags={setMetaTags} infoJson={infoJson} />
              </Suspense>
            )}
          </Route>

          <>
            <Route
              path={"/tv"}
              element={
                <Suspense fallback={<PageHolder />}>
                  <Tv
                    infoJson={infoJson}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />
          </>
        </Routes>
      </Wrapper>
    </>
  );
}
