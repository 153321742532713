import React, { lazy, Suspense, useState } from "react";
import { ReactComponent as Share } from "../../../../assets/icons/btn/icon_share.svg";
import { ReactComponent as Detalhes } from "../../../../assets/icons/btn/ver_detalhes.svg";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import { ReactComponent as Comparar } from "../../../../assets/icons/btn/comparar.svg";
import {
  tratarLink,
  tratarPrecoPromo,
} from "../../../helpers/tratarInfoCarros";
import * as apiJson from "../../../../api/apiJson";

import css from "../listagem/opcoes.module.css";
import { Link } from "react-router-dom";

const MessageForm = lazy(() => import("../../ficha/message/MessageForm"));
const ModalPartilhar = lazy(() => import("../../../modal/ModalPartilhar"));

export default function OpcoesDestaqueBanner({
  nodeRef,
  carro,
  selectedItems,
  addToCompare,
  removeFromCompare,
  infoJson,

  setState,
}) {
  const [modalTitulo, setModalTitulo] = useState("");
  const [modalimagemSeloGrande, setModalImagemSeloGrande] = useState("");
  const [modalLocation, setModalLocation] = useState("");
  const [modalPartilhar, setModalPartilhar] = useState(false);
  const [showContactar, setShowContactar] = useState(false);
  const location = tratarLink(
    carro.Marca.Nome,
    carro.Modelo.Nome,
    carro.CodViatura,
    carro.Viatura
  );
  const handlerModalPartilhar = (location, Titulo, imagemSeloGrande) => {
    let imagemSeloGrandeM;
    imagemSeloGrande !== ""
      ? (imagemSeloGrandeM = imagemSeloGrande)
      : (imagemSeloGrandeM =
          "http://box.autocompraevenda.net/substituicao/media.jpg");
    setModalLocation(location);
    setModalTitulo(Titulo);
    setModalImagemSeloGrande(imagemSeloGrandeM);
    setModalPartilhar(true);
    setState(false);

    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }

    apiJson.createMetaTag({
      url: location,
      title: Titulo,
      description: `Encontre ${carro.Marca.Nome} ${carro.Modelo.Nome} ${
        carro.Motorizacao
      } ${carro.Estado.Nome} para venda em ${Morada}. ${
        carro.Marca.Nome
      } barato por ${tratarPrecoPromo(carro.Preco)} só na ${
        infoJson.Stand.Nome
      }`,
      thumbnail: imagemSeloGrande,
    });
  };
  return (
    <>
      <div ref={nodeRef} className={`${css.box} ${css.boxDestaque} `}>
        <div
          className={`${css.rectangle} ${css.rectangleBanner}   btnLineTextBanner`}
        >
          <Link
            to={location}
            className={`${css.rectangle} ${css.rectangleBanner}  btnLineTextBanner`}
          >
            <div>
              <Detalhes style={{ width: "20px", marginRight: "10px" }} />
              Detalhes
            </div>
          </Link>
        </div>

        {infoJson.Layout.Carros.Comparador && (
          <>
            {selectedItems &&
            selectedItems.filter((e) => e.CodViatura === carro.CodViatura)
              .length > 0 ? (
              <div
                className={`${css.rectangle} ${css.rectangleBanner}   btnLineTextBanner active`}
                onClick={() => removeFromCompare(carro)}
              >
                <div>
                  <Comparar style={{ width: "20px", marginRight: "10px" }} />
                  Remover
                </div>
              </div>
            ) : (
              <div
                className={`${css.rectangle}  ${css.rectangleBanner}  btnLineTextBanner`}
                onClick={() => addToCompare(carro)}
              >
                <div>
                  <Comparar style={{ width: "20px", marginRight: "10px" }} />
                  Comparar
                </div>
              </div>
            )}
          </>
        )}

        <div
          className={`${css.rectangle} ${css.rectangleBanner}  btnLineTextBanner`}
          onClick={() => {
            handlerModalPartilhar(
              location,
              `${carro.Marca.Nome} ${carro.Modelo.Nome} ${carro.Motorizacao}`,
              carro.Ficheiros[0].Ficheiro
            );
          }}
        >
          <div>
            <Share style={{ width: "20px", marginRight: "10px" }} />
            Partilhar
          </div>
        </div>
        <div
          className={`${css.rectangle} ${css.rectangleBanner}  btnLineTextBanner`}
          onClick={() => {
            setShowContactar(true);
            setState(false);
          }}
        >
          <div>
            <Info style={{ width: "20px", marginRight: "10px" }} />
            Mais Info
          </div>
        </div>
      </div>

      <Suspense fallback={<></>}>
        <ModalPartilhar
          show={modalPartilhar}
          onHide={() => {
            setModalPartilhar(false);
            setState(true);
          }}
          location={modalLocation}
          Localidade={modalTitulo}
          imagemSeloGrande={modalimagemSeloGrande}
          infoJson={infoJson}
        />
      </Suspense>

      <Suspense fallback={<></>}>
        <MessageForm
          show={showContactar}
          onHide={() => {
            setShowContactar(false);
            setState(true);
          }}
          infoJson={infoJson}
          viatura={carro}
        />
      </Suspense>
    </>
  );
}
