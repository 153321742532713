import React from "react";
import { Col } from "react-bootstrap";
import css from "../listagem/tag.module.css";
export default function TagBanner({
  vendido,
  reservado,
  tagImg,
  precoPromo,
  dispoBreve,
  ivaDedutivel,
  ivaDescriminado,
}) {
  return (
    <>
      {vendido === true ? (
        <>
          <Col xs={12} className={css.cantoSuperiorImagemBanner}>
            <span className={`${css.badgeBanner} ${css.badgeV}  badged`}>
            Vendido
            </span>
          </Col>
        </>
      ) : reservado === true ? (
        <Col xs={12} className={css.cantoSuperiorImagemBanner}>
          <span className={`${css.badgeBanner} ${css.badgeR}  badged`}>
            Reservado
          </span>
        </Col>
      ) : tagImg !== "" ? (
        <Col xs={12} className={css.cantoSuperiorImagemBanner}>
          <span className={`${css.badgeBanner} badged`}>{tagImg}</span>
        </Col>
      ) : precoPromo !== "" ? (
        <Col xs={12} className={css.cantoSuperiorImagemBanner}>
          <span className={`${css.badgeBanner} ${css.badgeP}  badged`}>
            PROMOÇÃO
          </span>
        </Col>
      ) : dispoBreve === true ? (
        <Col xs={12} className={css.cantoSuperiorImagemBanner}>
          <span className={`${css.badgeBanner} badged`}>
            DISPONIVEL BREVEMENTE
          </span>
        </Col>
      ) : ivaDedutivel === true ? (
        <Col xs={12} className={css.cantoSuperiorImagemBanner}>
          <span className={`${css.badgeBanner} badged`}>IVA DEDUTIVEL</span>
        </Col>
      ) : (
        ivaDescriminado === true && (
          <Col xs={12} className={css.cantoSuperiorImagemBanner}>
            <span className={`${css.badgeBanner} badge`}>IVA DISCRIMINADO</span>
          </Col>
        )
      )}
    </>
  );
}
